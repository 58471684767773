import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374230-647f77d0-4e50-44de-a663-b94a0e63befe.png" />
    <h2>{`Usage`}</h2>
    <p>{`A progress bar is a simple chart that can be used to show how complete something is, or visualize parts that make up a whole.`}</p>
    <h3>{`Best practices`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374232-f0f8d3dd-b4da-4f8b-a528-932d82b012da.png" />
    <p>{`When using multiple segments, use colors that are easy to tell apart and include a legend that identifies what each segment represents.`}</p>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374235-3cfc45ba-e0f6-4c50-9d73-de812b084d59.png" />
    <p>{`Default to pairing the progress bar with text that explicitly shows the value(s) of the filled segment(s). Text values are not explicitly required, but should only be omitted when the progress is intentionally vague.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img role="presentation" width="456" src="https://user-images.githubusercontent.com/2313998/227374238-9cc0053c-6b09-45c4-bd6a-e5abbf3a8cc2.png" />
    <Caption mdxType="Caption">Limit the number of segments.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img role="presentation" width="456" src="https://user-images.githubusercontent.com/2313998/227374240-aa7e2faa-d7cb-4862-ac07-1d755eff50d5.png" />
    <Caption mdxType="Caption">Don't overload a progress bar with too many segments.</Caption>
  </Dont>
    </DoDontContainer>
    <p>{`When using multiple segments, avoid overwhelming users by with too many segments. This is especially important for narrow progress bars. Consider a different kind of chart for these cases.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`With multiple segments`}</h3>
    <p>{`A progress bar may use segments to represent multiple parts of a whole.`}</p>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374241-9f750ba7-993f-4ae6-aaa0-71189cea7aad.png" />
    <h3>{`Color`}</h3>
    <p>{`The color of the filled part(s) of the progress bar may be customized. Default to using `}<a parentName="p" {...{
        "href": "https://primer.style/primitives/colors#success"
      }}><inlineCode parentName="a">{`success.emphasis`}</inlineCode></a>{` for a progress bar that only shows a single segment.`}</p>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374242-17251995-3001-4fca-b450-eb9c510bee22.png" />
    <h3>{`Size`}</h3>
    <p>{`The size may be adjusted to adapt the progress bar to different contexts.`}</p>
    <p>{`Size options:`}</p>
    <ul>
      <li parentName="ul">{`Small`}</li>
      <li parentName="ul">{`Medium (default)`}</li>
      <li parentName="ul">{`Large`}</li>
    </ul>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374243-798808a3-1cb0-40b1-827c-e06925d1d4a4.png" />
    <h3>{`Width`}</h3>
    <p>{`By default, a progress bar will fill it's parent. However, a progress bar can be any width.`}</p>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374245-3e64d14f-5cf8-4710-bc34-1393bd39877a.png" />
    <h2>{`Accessibility`}</h2>
    <p>{`When rendering multiple segments, the segment colors should have a 3:1 contrast ratio with colors of adjacent segments.`}</p>
    <p>{`Progress bars can only be interpreted by sighted users. Include a text description to support assistive technologies like screen readers.`}</p>
    <p>{`For example, "tasks: 8 of 10 complete" for a single segment, or "tasks: 80 done, 14 in progress, 6 open" for multiple segments.`}</p>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374246-1e06193f-34e8-411f-9aee-6eb212923059.png" />
    <p>{`If the progress bar value is already shown as text adjacent to the progress bar, don't use a redundant label for the progress bar.`}</p>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/227374248-4c1fdb50-903a-4725-93e9-40eabb11baca.png" />
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="ProgressBar" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      